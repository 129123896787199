<template>
  <div v-if="state == availStates.PENDING">
    <div class="progress"></div>
    <div class="info-box">
      <div class="columns">
        <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
          <a class="bankid-text" href=""><span class="icon"><img src="@/assets/bankid.svg"></span> <span>{{ message }}</span></a>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="state == availStates.FAILED" class="columns">
    <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
      <b-message title="Fel vid signering" type="is-danger" :closable="false">
        {{ message }}
      </b-message>
    </div>
  </div>
  <div v-else-if="state == availStates.SUCCESS" class="columns">
    <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
      <b-message title="Signerad" type="is-success" :closable="false">
        {{ message }}
      </b-message>
    </div>
  </div>
</template>

<script>

export default {
  name: 'bank-id-sign',
  props: {
    debug: {
      type: Boolean,
      default: false
    },
    pNumber: {
      type: String,
      default: ''
    },
    collectInterval: {
      type: Number,
      default: 2000
    },
    userVisibleData: {
      type: String,
      default: ''
    },
    userNonVisibleData: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      personNumber: this.pNumber,
      showLoginError: false
    }
  },
  computed: {
    validPersonNumber: function () {
      return this.validPersonal(this.personNumber)
    },
    showIsError: {
      get: function () {
        if (this.validPersonNumber) return false
        return this.showLoginError
      },
      set: function (newValue) {
        this.showLoginError = newValue
      }
    },
    availStates: function () {
      return this.$store.state.session.availStates
    },
    state: function () {
      return this.$store.state.session.authState
    },
    message: function () {
      return this.$store.state.session.message
    }
  },
  methods: {
    startSign (personNumber = null) {
      if (!this.validPersonNumber) {
        this.showIsError = true
        return
      }
      this.$store.dispatch('startSign', {
        personNumber,
        debug: this.debug,
        collectInterval: this.collectInterval,
        userVisibleData: this.userVisibleData,
        userNonVisibleData: this.userNonVisibleData
      })
    },
    formatPersonal (pn) {
      if (pn == null) return null
      return pn.replace('-', '')
    },
    validPersonal (pn) {
      if (pn == null) return false
      pn = pn.replace(/[^0-9]|[-]/g, '').toString()
      if (!pn.match(/[0-9]{12}/)) return false
      if (!pn.match(/([0-9][0-9]).{8}/)) return false
      if (!pn.match(/.{2}(0[1-9]|1[0-2]).{6}/)) return false
      if (!pn.match(/.{4}(0[1-9]|[1-2][0-9]|3[0-1]).{4}/)) return false
      return true
    }
  },
  watch: {
    state (state) {
      this.$emit('stateChanged', state)
    }
  }
}
</script>

<style scoped lang="scss">
  $deep-sapphire: #012169;
  $lochmara: #0085CA;
  .is-bankid-field {
    position: relative;
    label {
      position: absolute;
      top: 20px;
      left: 14px;
      z-index: 90;
      font-size: 1.2rem;
      margin: 0;
      pointer-events: none;
      transform-origin: top left;
      transition: transform 150ms ease;
      font-weight: 400;
    }
    &.has-input {
      label {
         transform: scale(0.65) translateY(-15px);
      }
    }
    input {
      padding: 1.6rem 0.8rem 0.8rem 0.8rem;
      height: auto;
      font-size: 1.2rem;
      &::-ms-clear {
        display: none;
      }
    }
    .icon {
      width: 2.85em;
      height: 100%;
      &.is-right {
        right: 5px;
      }
    }
  }
  .icon-bankid {
    height: 1.5rem;
  }

  .button.is-fullwidth {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    height: auto;
  }
  .control .button.is-primary {
    background-color: $deep-sapphire;
  }

  .progress {
    height: 1rem;
    width: 100%;
    background: #333;
    border-radius: 0px;
    background: radial-gradient(circle at center, $lochmara 0, $deep-sapphire 100%) repeat center center;
    margin-bottom: 0px;
    animation: progress 5s linear infinite;
    @media screen and (max-width: 768px) {
      animation: progress-full-width 5s linear infinite;
    }
  }
  .info-box {
    padding: 3rem 0;
    background: #f6fcff;
    .bankid-text {
      color: $deep-sapphire;
      display: flex;
      align-items: center;
      font-weight: 800;
      font-size: 0.9rem;
      span {
        font-family: "Nexa", sans-serif;
      }
      .icon {
        margin-right: 0.7rem;
        width: 2em;
        height: 2em;
      }
    }
  }
  @keyframes progress {
    from { background-position: 0 0; }
    to { background-position: 50vw 0; }
  }
  @keyframes progress-full-width {
    from { background-position: 0 0; }
    to { background-position: 100vw 0; }
  }
</style>
