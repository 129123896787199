<template>
  <div class="pensionables">
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Hej {{ givenName }}
          </h1>
          <h2 class="subtitle">
            Här är alla pensionsberättigade som du är rådgivare för
          </h2>
          <h2 class="subtitle is-size-6">Visa för månad/år:
            <select v-model="pickedLedgerMonth">
              <option v-for="ledger in ledgerMonths"
                :value="ledger"
                :key="ledger">
                {{ ledger }}
              </option>
            </select>
          </h2>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <!--
        <table class="table">
          <thead>
            <tr>
              <th>Namn</th>
              <th>Personnummer</th>
              <th>Företag</th>
              <th>Organisationsnummer</th>
              <th>Totalt kapitalvärde</th>
              <th>Tillgängligt kapital</th>
              <th>Utbetalning</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="beneficiary in beneficiaries" :key="beneficiary.personalNumber">
              <td>{{ beneficiary.name }}</td>
              <td>{{ beneficiary.personalNumber }}</td>
              <td>{{ beneficiary.companyName }}</td>
              <td>{{ beneficiary.companyOrgno }}</td>
              <td>0 kr</td>
              <td>0 kr</td>
              <td>0 kr</td>
            </tr>
          </tbody>
        </table>
        -->
          <TableRender :headers="[
            { key: 'name', title: 'Namn', is_actAs: true},
            { key: 'personalNumber', title: 'Personnummer' },
            { key: 'companyName', title: 'Företag' },
            { key: 'companyOrgno', title: 'Org.nummer' },
            { key: 'lastUpdate', title: 'Senast uppdaterad' },
            { key: 'capitalValue', title: 'Totalt kapitalvärde', type: 'money' },
         /* { key: 'availibleExcess', title: 'Tillgängligt kapital', type: 'money' }, */
            { key: 'payout', title: 'Utbetalning Lön', type: 'money' },
            { key: 'payoutTax', title: 'Utbetalning Skatt' },
            { key: 'payoutPeriod', title: 'Utbetalning Period' },
          ]"
          :content="beneficiaries"/>
      </div>
    </section>
  </div>
</template>

<script>
import leftPad from 'left-pad'
import TableRender from '@/components/TableRender'

export default {
  name: 'pensionables',
  components: {
    TableRender
  },
  data () {
    return {
      pickedLedgerMonth: null
    }
  },
  methods: {
    monthToHuman (date) {
      return leftPad(date.year, 4, '0') + '-' +
        leftPad(date.month, 2, '0')
    },
    dateToHuman (date) {
      return leftPad(date.year, 4, '0') + '-' +
        leftPad(date.month, 2, '0') + '-' +
        leftPad(date.day, 2, '0')
    }
  },
  computed: {
    givenName () {
      return this.$store.state.profile.delegate ? this.$store.state.profile.delegate.delegateName : null
    },
    ledgerMonths () {
      return this.beneficiaries
        .map(ben => ben._forMonths.map(v => this.monthToHuman(v)))
        .reduce((cur, ben) => cur.concat(ben), [])
        .filter((v, i, s) => s.indexOf(v) === i)
    },
    beneficiaries () {
      if (!this.$store.state.profile || !this.$store.state.profile.beneficiaries) {
        return []
      }
      return this.$store.state.profile.beneficiaries.map(ben => {
        const sortedLedgers = [...ben.ledger].sort((a, b) => {
          if (a.year !== b.year) {
            return a.year - b.year
          }
          if (a.month !== b.month) {
            return a.month - b.month
          }
          return a.day - b.day
        })
        const latestLedger = sortedLedgers.length > 0 ? sortedLedgers[sortedLedgers.length - 1] : null

        if (!this.pickedLedgerMonth) {
          this.pickedLedgerMonth = this.monthToHuman(latestLedger.forMonth)
        }

        const pickedLedger = sortedLedgers.reduce((cur, led) => {
          if (this.pickedLedgerMonth === this.monthToHuman(led.forMonth)) {
            cur = led
          }
          return cur
        }, latestLedger)

        let total = 0
        if (pickedLedger && pickedLedger.rows) {
          pickedLedger.rows.forEach(row => {
            total += Math.floor(row.amountInCent / 100)
          })
        }
        let payoutPeriod = '-'
        if (pickedLedger.payFirstday && pickedLedger.payLastday) {
          payoutPeriod = `${this.dateToHuman(pickedLedger.payFirstday)} till ${this.dateToHuman(pickedLedger.payLastday)}`
        } else if (pickedLedger.payOneoff) {
          payoutPeriod = `${this.dateToHuman(pickedLedger.payOneoff)}`
        }
        return {
          _forMonths: sortedLedgers.map(l => ({ month: l.forMonth.month, year: l.forMonth.year })),
          name: {
            user: ben,
            value: ben.name
          },
          personalNumber: {
            value: ben.personalNumber
          },
          companyName: {
            value: ben.companyName
          },
          companyOrgno: {
            value: ben.companyOrgno
          },
          lastUpdate: {
            value: this.dateToHuman(pickedLedger.lastUpdate)
          },
          capitalValue: {
            value: total
          },
          /*
          availibleExcess: {
            value: pickedLedger && pickedLedger.totalExcessInCent > 0 ? Math.floor(pickedLedger.totalExcessInCent / 100) : 0
          },
          */
          payout: {
            value: pickedLedger && pickedLedger.payInCent > 0 ? Math.floor(pickedLedger.payInCent / 100) : null
          },
          payoutTax: {
            value: pickedLedger && pickedLedger.payTaxInPercent ? `${pickedLedger.payTaxInPercent} %` : ''
          },
          payoutPeriod: {
            value: payoutPeriod
          }
        }
      })
    }
  },
  watch: {
    '$store.state.profile.beneficiaries' () {
      this.pickedLedgerMonth = null
    },
    '$store.state.profile.delegate' () {
      this.pickedLedgerMonth = null
      if (!this.$store.state.profile.delegate || !this.$store.state.profile.delegate.delegateId) {
        this.$router.push('/')
      }
    }
  },
  beforeCreate: function () {
    if (!this.$store.state.profile.delegate || !this.$store.state.profile.delegate.delegateId) {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
  .pensionables .table-container {
    overflow-y: scroll;
    overflow-x: scroll;
    min-height: 400px;
    max-height: calc(100vh - 330px);
    border: 2px solid #ececec;
    table td span {
      white-space: nowrap;
    }
    thead th {
      position: sticky;
      top: 0;
      background-color: white;
      border-bottom: 1px solid #f1f1f1;
    }
  }
</style>
