<template>
  <div class="home">
    <LoginRequired />
    <div class="back-button ml-4 mb-2">
      <router-link to="/">
        <font-awesome-icon icon="chevron-left" />
        Tillbaka
      </router-link>
    </div>
    <section class="section is-medium has-light-border-top" v-if="error">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-7">
            <b-message title="Ett fel uppstod" type="is-danger" aria-close-label="Stäng meddelande" @close="$store.commit('setError', false); $router.push('/')">
              Ett fel uppstod vid hämtning av uppgifter, testa igen om ett par minuter. Var god kontakta Stifta om problemet kvarstår
            </b-message>
          </div>
        </div>
      </div>
    </section>
    <section v-else-if="status !== ''" class="section is-medium has-light-border-top">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-7">
              <b-message :title="election.IsNominating ? 'Tack för din nominering' : 'Tack för din röst'" type="is-info" aria-close-label="Stäng meddelande" @close="$router.push('/')">
                {{ status }}
                <p class="has-text-grey-light">
                  <button class="button is-medium is-info" style="float:right" @click="$router.push('/')">Tillbaka</button>
                </p>
              </b-message>
          </div>
        </div>
      </div>
    </section>
    <section v-else-if="prompt !== ''" class="section is-medium has-light-border-top">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half">
            <b-message title="Ett fel uppstod" type="is-danger" aria-close-label="Stäng meddelande" @close="prompt = ''">
              {{ prompt }}
            </b-message>
          </div>
        </div>
      </div>
    </section>
    <template v-else-if="election">
      <section v-show="[availStates.PENDING, availStates.FAILED, availStates.SUCCESS].includes(state)" class="section is-medium has-light-border-top">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-half">
              <bank-id-sign
                ref="bankIdSign"
                :pNumber="account.personalNumber"
                :userVisibleData="userVisibleData"
                :userNonVisibleData="userNonVisibleData"
                @stateChanged="handleStateChange" />
            </div>
          </div>
        </div>
      </section>
      <section v-show="![availStates.PENDING, availStates.FAILED, availStates.SUCCESS].includes(state)" class="section is-medium has-light-border-top">
        <div class="container">
          <div class="columns">
            <div class="column is-full has-text-centered">
              <h1 class="title is-size-3 has-text-black">
                Hej, {{ userNiceName }}
              </h1>
              <p v-if="election.IsNominating" class="subtitle has-text-black is-size-6">
                Här kan du nominera {{ election.NominationType == 'worker' ? 'arbetstagarkandidater' : 'arbetsgivarkandidater' }} till styrelsen för {{ election.Name }}.
                <template v-if="election.Organization">
                  <br>Nominiering sker för {{ election.Organization.Name }} med org nr. {{ election.Organization.OrganizationNumber }}.
                </template>
              </p>
              <p v-else-if="election.IsVoting && election.Version === 2" class="subtitle has-text-black is-size-6">
                Här kan du rösta på 2 kandidater till styrelsen för {{ election.Name }}.
                <template v-if="election.Organization">
                  <br>Röstning sker för {{ election.Organization.Name }} med org nr. {{ election.Organization.OrganizationNumber }}.
                </template>
              </p>
              <p v-else-if="election.IsVoting" class="subtitle has-text-black is-size-6">
                Här kan du rösta {{ election.NominationType == 'worker' ? 'arbetstagarkandidater' : 'arbetsgivarkandidater' }} till styrelsen för {{ election.Name }}.
                <template v-if="election.Organization">
                  <br>Röstning sker för {{ election.Organization.Name }} med org nr. {{ election.Organization.OrganizationNumber }}.
                </template>
              </p>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-7-desktop is-7-tablet has-text-centered">
              <template v-if="election.IsNominating">
                <table class="table elections-table is-fullwidth is-striped is-hoverable">
                  <thead>
                    <tr>
                      <th class="is-size-6 has-text-weight-medium" colspan="2">För och efternamn</th>
                      <th class="is-size-6 has-text-weight-medium">Mobilnummer</th>
                      <th class="is-size-6 has-text-weight-medium">E-post</th>
                      <th class="is-size-6 has-text-weight-medium">Företag</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="candidate, i in candidates" :key="i">
                      <td class="is-size-5 center-icon pr-0">
                        <font-awesome-icon :icon="['far', 'user']" :aria-hidden="true"></font-awesome-icon>
                      </td>
                      <td>
                        <p class="control">
                          <input v-model="candidate.Name" class="input" type="text" placeholder="Namn" />
                        </p>
                      </td>
                      <td>
                        <p class="control">
                          <input v-model="candidate.Phone" class="input" type="text" placeholder="Mobilnummer" />
                        </p>
                      </td>
                      <td>
                        <p class="control">
                          <input v-model="candidate.Email" class="input" type="text" placeholder="E-post" />
                        </p>
                      </td>
                      <td>
                        <p class="control">
                          <input v-model="candidate.Company" class="input" type="text" placeholder="Company" />
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <template v-else-if="election.IsVoting && election.Version === 2">
                <table class="table elections-table election-v2 is-fullwidth">
                  <thead>
                    <tr>
                      <th class="is-size-6 has-text-weight-medium">Röst</th>
                      <th class="is-size-6 has-text-weight-medium">Kandidat</th>
                      <th class="is-size-6 has-text-weight-medium">Beskrivning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(candidate, i) in election.Candidates" :key="candidate.ID">
                      <td class="is-size-5 pr-0 pl-0 has-text-centered">
                        <input type="checkbox" style="width: 25px; height: 25px; margin-top: 0.5em" v-model="election.Candidates[i].selected" :disabled="!election.Candidates[i].selected && selectedCandidates.length >= 2" />
                      </td>
                      <td>
                        <p> {{ candidate.Name }} </p>
                      </td>
                      <td>
                        <p
                          v-if="candidateDescriptions[candidate.ID]"
                          v-html="candidateDescriptions[candidate.ID]"
                          class="candidate-description"></p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <template v-else-if="election.IsVoting">
                <table class="table elections-table is-fullwidth">
                  <thead>
                    <tr>
                      <th class="is-size-6 has-text-weight-medium">Röst</th>
                      <th class="is-size-6 has-text-weight-medium">Kandidat</th>
                      <th class="is-size-6 has-text-weight-medium">Arbestgivare eller Arbetstagare</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(candidate, i) in election.Candidates" :key="candidate.ID">
                      <td class="is-size-5 pr-0 pl-0 has-text-centered">
                        <input type="checkbox" style="width: 25px; height: 25px; margin-top: 0.5em" v-model="election.Candidates[i].selected" :disabled="!election.Candidates[i].selected && selectedCandidates.length >= 2" />
                      </td>
                      <td>
                        <p> {{ candidate.Name }} </p>
                      </td>
                      <td>
                        <p> {{ candidateTypeNiceName(candidate.CandidateType) }} </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <div class="column is-narrow">
                <p class="control vote-button">
                  <button class="button is-primary" @click.prevent="startSign" :disabled="!(election.IsNominating || (election.IsVoting && selectedCandidates.length > 0))">
                    <font-awesome-icon :icon="['far', 'box-ballot']" :aria-hidden="true" class="mr-2"></font-awesome-icon>
                    <span>Skicka in {{ election.IsNominating ? 'nominering' : 'röst' }}</span>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import { marked } from 'marked'

import LoginRequired from '@/components/LoginRequired'
import BankIdSign from '@/components/BankIdSign'

export default Vue.extend({
  name: 'home',
  components: {
    LoginRequired,
    BankIdSign
  },
  data () {
    return {
      collectInterval: 2000,
      status: '',
      prompt: '',
      state: 0,
      candidates: [
        { Name: '', Phone: '', Email: '', Company: '' },
        { Name: '', Phone: '', Email: '', Company: '' },
        { Name: '', Phone: '', Email: '', Company: '' },
        { Name: '', Phone: '', Email: '', Company: '' }
      ],
      votes: []
    }
  },
  prototype: {
    moment
  },
  methods: {
    startSign () {
      // Verify and format data to be posted
      let votes = this.candidates

      if (this.election.IsVoting) {
        // Create new array of selected candidates
        votes = this.selectedCandidates.reduce((acc, candidate) => {
          acc.push({
            ElectionID: this.election.ID,
            OrganizationID: this.election.Organization ? this.election.Organization.ID : null,
            CandidateID: candidate.ID
          })
          return acc
        }, [])
      } else {
        votes = votes.filter((candidate) => {
          return candidate.Name !== '' && candidate.Company !== ''
        })

        if (votes.length === 0) {
          this.prompt = 'Du måste fylla i minst en av raderna'
          return
        }
      }

      this.votes = votes
      this.$refs.bankIdSign.startSign(this.account.personalNumber)
    },
    castVote  () {
      const apiClient = this.$store?.state?.election?.apiClient
      if (!apiClient) {
        this.prompt = 'Ett fel uppstod när vi försökte skicka dina val, (No ApiClient)'
        return
      }

      apiClient.castElectionVote(this.election.ID, this.election.Organization ? this.election.Organization.ID : null, this.votes).then((resp) => {
        this.status = resp?.data?.status
      }).catch((err) => {
        this.$store.commit('setError', err)
      })
    },
    candidateTypeNiceName (candidateType) {
      switch (candidateType) {
        case 'worker':
          return 'Arbetstagare'
        case 'company':
          return 'Arbestgivare'
      }
    },
    handleStateChange (state) {
      this.state = state
      if (this.availStates.SUCCESS === state) {
        this.castVote()
      } else if (this.availStates.ERROR === state) {
        this.prompt = 'Ett fel uppstod när vi försökte skicka dina val'
      }
    }
  },
  watch: {
  },
  computed: {
    account () {
      return (this.$store.getters.session != null) ? this.$store.getters.session.account : null
    },
    election () {
      const id = parseInt(this.$route.params.id)
      const orgid = parseInt(this.$route.query.org || '0')
      return this.elections.find(e => {
        if (orgid !== 0) {
          return e.ID === id && e.Organization && e.Organization.ID === orgid
        }
        return e.ID === id
      })
    },
    elections () {
      return this.$store.state.election.elections ? this.$store.state.election.elections.Elections : []
    },
    candidateDescriptions () {
      if (!this.election) {
        return {}
      }
      return this.election.Candidates.reduce((acc, candidate) => {
        acc[candidate.ID] = marked.parse(candidate.Description)
        return acc
      }, {})
    },
    error () {
      return this.$store.state.election.error
    },
    userNiceName () {
      let niceName = (this.account != null) ? this.account.name : null
      if (niceName != null) {
        niceName = niceName.toLowerCase()
        let split = niceName.split(' ')
        for (let i = 0; i < split.length; i++) {
          split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1)
        }
        niceName = split.join(' ')

        split = niceName.split('-')
        for (let i = 0; i < split.length; i++) {
          split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1)
        }
        niceName = split.join('-')
      }

      return niceName
    },
    selectedCandidates () {
      return this.election.Candidates.filter(c => c.selected)
    },
    userVisibleData () {
      return `Jag ${this.election.IsVoting ? 'röstar på' : 'nominerar'}${this.election.Organization ? ' åt ' + this.election.Organization.Name + ' (' + this.election.Organization.OrganizationNumber + ')' : ''} kandidater till styrelsen för ${this.election.Name}

Det går inte att ändra i efterhand.`
    },
    userNonVisibleData () {
      return JSON.stringify(this.election.IsVoting ? this.selectedCandidates : this.candidates)
    },
    availStates: function () {
      return this.$store.state.session.availStates
    }
  },
  mounted () {
    // Check if user has already voted in this election
    if ((this.election.IsVoting && this.election.HasVoted) || (this.election.IsNominating && this.election.HasNominated)) {
      this.$router.push('/')
    }
  },
  beforeRouteLeave (to, from, next) {
    // Refresh the election data before leaving
    this.$store.dispatch('fetchElectionData')
    next()
  }
})
</script>

<style scoped lang="scss">
  $deep-sapphire: #012169;
  $lochmara: #0085CA;
  $calypso: #34657F;
  $silver-chalice: #A7A8AA;
  $black: #2a2c32;
  $gray: #737373;
  $white: #F5F5F5;
  $light-gray: #efefef;

  .capital-change {
    position: absolute;
    margin: 0.75em 0 0 1em;
    padding: 0.25em 0.75em;
    color: $gray;
    font-size: 0.6rem;
  }
  .capital-change.green {
    background: #E9FAE5
  }

  .capital-change.red {
    background: #FAE5E5
  }

  .capital-dash {
    color: $silver-chalice;
    position: relative;
    top: 1px;
  }

  .has-text-gray {
    color: $gray;
  }

  .button.payout {
    border-radius: 0;
    background: $black;
    color: $white;
    margin-top: 1em;
  }

  .profile-image {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    flex-shrink: 0;
  }

  .hero-body {
    background: $deep-sapphire;
    .container {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .title {
      margin-bottom: 20px;
      font-size: 37px;
      line-height: 45px;
    }
    .subtitle {
      font-size: 14px;
    }
  }
  .is-justified-start {
    justify-content: flex-start;
  }
  .is-aligned-start {
    align-items: flex-start;
  }
  .is-half {
    width: 50%;
  }
  .capital-header {
    color: $silver-chalice
  }
  .border-box {
    // display: flex;
    border: $silver-chalice 1px solid;
    padding: 5rem 0rem;
    flex-basis: 50%;
  }
  // .border-box:first-child {
  //   border-right: 0px
  // }
  .level-item {
    &.is-level-column {
      flex-direction: column;
      flex-shrink: 1;
    }
  }
  .footnote {
    padding: 0.25rem;
    transition: background 200ms linear, color 200ms linear;
  }
  .footnote:target {
    background: rgba($deep-sapphire, 0.07);
    border-radius: 2px;
    color: $deep-sapphire;
  }

  .notimplemented {
    background-color: yellow;
    color: red;
  }

  .control .button.is-primary {
    background-color: $deep-sapphire;
  }

  .center-icon {
    vertical-align: middle;
  }

  .vote-button {
    display: flex;
    justify-content: flex-end;
  }

  .elections-table {
    border-collapse: collapse;
    margin-bottom: 1rem;
    padding: 0;
    border: 2px solid $light-gray;
  }

  .elections-table th {
    text-align: left;
    padding: 0.25rem 1rem;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.5rem;
    border: none;
    border-bottom: 1px solid $light-gray;
  }

  .elections-table td {
    text-align: left;
    padding: 0.25rem 1rem;
    vertical-align: middle;
    border: none;
  }

  .elections-table.election-v2 td {
    vertical-align: top;
    padding-top: 0.25rem;
  }
  .elections-table.election-v2 td p {
    padding-top: 0.4rem;
  }
  .elections-table.election-v2 td:nth-child(2) {
    white-space: nowrap;
  }
  .candidate-description {
    font-size: 0.8rem;
  }
</style>
